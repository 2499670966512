// Common
export const API_ERROR = 'API_ERROR'
export const SET_LANG = 'SET_LANG'
export const SET_HIGH_CONTRAST = 'SET_HIGH_CONTRAST'

// Authentication
export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'
export const GET_CONFIG = 'GET_CONFIG'
export const GET_ZONES = 'GET_ZONES'

//Packs
export const GET_PACKS = 'GET_PACKS'
export const GET_SUGGESTIONS = 'GET_SUGGESTIONS'
export const GET_ORDER = 'GET_ORDER'
export const RELEASE_ORDER = 'RELEASE_ORDER'
export const GET_BOXES = 'GET_BOXES'
export const SET_SEARCHING = 'SET_SEARCHING'
export const SET_PROCESSING = 'SET_PROCESSING'
export const SET_LAST_SCANNED = 'SET_LAST_SCANNED'
export const SET_FILTERS = 'SET_FILTERS'

//Shipping
export const SELECT_SHIPPING = 'SELECT_SHIPPING'
export const GET_SHIPPING = 'GET_SHIPPING'
export const SAVE_SHIPPING = 'SAVE_SHIPPING'
export const DELETE_SHIPPING = 'DELETE_SHIPPING'
