import { withRouter, Redirect } from 'react-router-dom'
import React from 'react'
import Popup from 'react-popup'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { getOrder, finishOrder, selectOrder, releaseOrder, printErrorLabel } from '../actions/PackActions'
import { selectShipping, deleteShipping } from '../actions/ShippingActions'
import ProgressBar from './ProgressBar'
import ProductListItem from './ProductListItem'
import { b64EncodeUnicode } from '../utils/Tools'
import { ReactComponent as Check } from '../assets/check.svg'
require('./ShippingList')
require('./MaterialsList')
require('./Substitutes')

function getProductListItem(prod, parent, group_treshold) {
  // render substitutes
  const orderSubstitutes = parent.getOrderSubstitutes(prod.line_id) || {}
  const product = {
    ...prod,
    ...(prod.substitute_products ? {
      substitute_products: prod.substitute_products.map(sub => ({
        ...sub,
        value: (orderSubstitutes || {})[sub.product_id]
      }))
    } : {})
  }
  if(product.substitute_products && product.substitute_products.some(sub => (sub.value && sub.value > 0))){
    let arr = []
    product.substitute_products.filter(sub => (sub.value && sub.value > 0)).forEach(sub => {
      arr.push(<ProductListItem
                key={product.line_id+'.'+sub.product_id}
                product={{
                  ...product,
                  default_code: sub.default_code,
                  quote_default_code: sub.default_code,
                  product_name: sub.description,
                  product_id: sub.product_id,
                  product_uom_qty: sub.value,
                  line_qty: product.product_uom_qty
                }}
                qty={sub.value}
                supercount={true}
                productTracking={false}
                parent={parent}/>
      )
    })
    return arr
  }
  // render substitutes END

  const productTracking = product.product_tracking !== 'none'
  let supercount = productTracking ? false : product.product_uom_qty >= group_treshold
  let k = 1
  if (supercount){
    k++
    return (<ProductListItem
             key={product.line_id+'.'+product.product_id+'.'+k}
             product={product}
             qty={product.product_uom_qty}
             supercount={supercount}
             parent={parent}/>
    )
  } else {
    let arr = []
    for (var i = 0; i < product.product_uom_qty; i++) {
      k++
      arr.push(<ProductListItem
                key={product.line_id+'.'+product.product_id+'.'+k}
                product={product}
                qty={(product.product_uom_qty - i) < 1 ? Math.round((product.product_uom_qty - i)*100)/100 : 1}
                supercount={supercount}
                productTracking={productTracking}
                parent={parent}/>
      )
    }
    return arr
  }

}

const inpost_size_options = [
  ['A','A'],
  ['B','B'],
  ['C','C'],
]

const orlen_size_options = [
  ['S','S'],
  ['M','M'],
  ['L','L'],
  ['Mini','MINI'],
]

const pp_size_options = [
  ['XS','xsmall'],
  ['S','small'],
  ['M','medium'],
  ['L','large'],
  ['XL','xlarge'],
  ['XXL','xxlarge'],
]

const wza_size_options = [
  ['S','s'],
  ['M','m'],
  ['L','l'],
]

const mapStateToProps = state => ({
  authenticated: state.auth.session.id !== false,
  product_confirmation_method: state.config.product_confirmation_method,
  auto_select_first: state.config.auto_select_first,
  verify_qty: !!state.config.verify_qty,
  confirm_note: state.config.confirm_note,
  show_company: state.config.show_company,
  show_shop: state.config.show_shop,
  carrier_selection: state.config.carrier_selection,
  inventory_manage_materials: state.config.inventory_manage_materials,
  use_packing_zone_error_label: state.config.use_packing_zone_error_label,
  material_products: state.config.material_products,
  inpost_dostawy: state.config.inpost_dostawy || [],
  pp_dostawy: state.config.pp_dostawy || [],
  wza_dostawy: state.config.wza_dostawy || [],
  orlen_dostawy: state.config.orlen_dostawy || [],
  add_package: state.config.add_package,
  add_delivery: state.config.add_delivery,
  group_treshold: state.config.group_treshold ? parseInt(state.config.group_treshold) : false,
  pack: state.router.location.query.pack ? parseInt(state.router.location.query.pack) : false,
  order_id: state.router.location.query.order ? parseInt(state.router.location.query.order) : false,
  order: state.pack.order,
  processing: state.pack.processing,
  last_scanned: state.pack.last_scanned,
  shipping: state.shipping.shipping
})

const mapDispatchToProps = dispatch => ({
  getOrderAction: (code, id) => dispatch(getOrder(code, id)),
  finishOrderAction: (data) => dispatch(finishOrder(data)),
  selectOrderAction: () => dispatch(selectOrder()),
  selectShippingAction: (id) => dispatch(selectShipping(id)),
  deleteShippingAction: (id) => dispatch(deleteShipping(id)),
  releaseOrderAction: (order_id) => dispatch(releaseOrder(order_id)),
  printErrorLabelAction: (order_id) => dispatch(printErrorLabel(order_id)),
})

class OrderComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      code: '',
      progress: 0,
      valid: false,
      orderSubstitutes: {}
    }
  }

  componentDidMount() {
    const { getOrderAction, authenticated, order_id, order, last_scanned, auto_select_first } = this.props
    if(authenticated && !order) getOrderAction(false, order_id)
    if(auto_select_first && order && last_scanned && CSS.escape(last_scanned).length > 0){
      let checkbox = document.querySelector('.produkt[data-barcode*="#'+CSS.escape(last_scanned)+'#"] .checkbox.mainCheckbox:not(.checked), '+
                                            '.produkt .bundleItemList tr[data-barcode*="#'+CSS.escape(last_scanned)+'#"] .checkbox:not(.checked), '+
                                            '.produkt[data-name="'+b64EncodeUnicode(last_scanned)+'"] .checkbox.mainCheckbox:not(.checked), '+
                                            '.produkt .bundleItemList tr[data-name="'+b64EncodeUnicode(last_scanned)+'"] .checkbox:not(.checked), '+
                                            '.produkt[data-code="'+CSS.escape(last_scanned)+'"] .checkbox.mainCheckbox:not(.checked), '+
                                            '.produkt .bundleItemList tr[data-code="'+CSS.escape(last_scanned)+'"] .checkbox:not(.checked)'
                                           )
      if(!checkbox){
        let code = document.querySelector('.produkt .default_code[data-code="'+CSS.escape(last_scanned)+'"]')
        if(code){
          code.closest('.produkt.ramka')
          checkbox = code.closest('.produkt.ramka').querySelector('.checkbox:not(.checked)')
        }
      }
      if(checkbox){
        let parent = checkbox.closest('.produkt.ramka')
        this.toggleCheckbox(checkbox, parent)
      }
    }
  }

  componentDidUpdate(){
    const { product_confirmation_method, shipping } = this.props
    if(['scan', 'scan_click'].includes(product_confirmation_method) && shipping === false){
      document.getElementById('scanner').focus()
    }
  }

  deselectOrder = (event) => {
    const {selectOrderAction, releaseOrderAction, order_id} = this.props
    releaseOrderAction(order_id)
    selectOrderAction()
  }

  doToggleCheckbox = (checkbox, parent) => {
    checkbox.classList.toggle('checked')

    document.querySelectorAll('.produkt').forEach(el => el.classList.remove('last_scanned'))
    checkbox.closest('[data-barcode]').classList.add('last_scanned')

    if(parent.querySelector('.bundleItemList')){
      if(checkbox.classList.contains('mainCheckbox')){
        document.querySelectorAll('.bundleItemList .checkbox').forEach(el => el.classList.toggle('checked', checkbox.classList.contains('checked')))
      } else {
        parent.querySelector('.product_checkbox .checkbox').classList.toggle('checked', !parent.querySelector('.bundleItemList .checkbox:not(.checked)'))
      }
    }

    this.toggleConfirmButton()
  }

  toggleCheckbox = (checkbox, parent) => {
    var cnt = parent.getAttribute('data-prods')
    let bundle_cnt = 0
    if(checkbox.classList.contains('multiproduct')){
      bundle_cnt = checkbox.closest('tr').getAttribute('data-qty')
      cnt = cnt+' x '+bundle_cnt
    }
    const {verify_qty, group_treshold} = this.props
    if(!verify_qty || (!parent.classList.contains('supercount') && !(bundle_cnt >= group_treshold)) ||
       checkbox.classList.contains('checked')
      ){
        this.doToggleCheckbox(checkbox, parent)
    } else{
      let self = this
      Popup.create({
        content: intl.get('Czy na pewno spakowałeś X pozycji tego SKU?', {cnt: cnt}),
        buttons: {
          right: [{
            text: intl.get('Tak'),
            key: 'enter',
            action: function () {
              Popup.close()
              self.doToggleCheckbox(checkbox, parent)
            }
          },
          {
            text: intl.get('Nie'),
            className: 'cancel-button',
            action: function () {
              Popup.close()
            }
          }]
        }
      })
    }
  }

   printErrorLabel = () => {
    const { order_id, printErrorLabelAction } = this.props
    Popup.create({
      content: intl.get('Na pewno chcesz drukować etykietę zastępczą?'),
      buttons: {
        right: [{
          text: intl.get('Tak'),
          key: 'enter',
          action: function () {
            Popup.close()
            printErrorLabelAction(order_id)
          }
        },
        {
          text: intl.get('Anuluj'),
          className: 'cancel-button',
          action: function () {
            Popup.close()
          }
        }]
      }
    })
  }

  handleScanner = (event) => {
    if (event.which === 13) {
      let ean = event.target.value
      let checkbox = false
      if(ean.length > 0){
        checkbox = document.querySelector('.produkt[data-barcode*="#'+ean+'#"]:not(.blocked_by_serial) .checkbox.mainCheckbox:not(.checked), .produkt .bundleItemList tr[data-barcode*="#'+ean+'#"] .checkbox:not(.checked)')
      }
      if(!checkbox){
        if(event.target.id === 'scanner'){
          Popup.create({
            content: intl.get('Nie znaleziono produktu z tym kodem.'),
            buttons: {
              right: [{text: 'OK',
                      className: 'ok',
                      key: 'enter',
                      action: function () {
                        Popup.close()
                        document.getElementById('scanner').value = ''
                        document.getElementById('scanner').focus()
                      }}]
            }
          })
        }
      } else {
        var parent = checkbox.closest('.produkt.ramka')
        this.toggleCheckbox(checkbox, parent)
        event.target.value = ''
      }
      event.preventDefault()
      event.stopPropagation()
      return false
    }
  }

  sizePopup = (data, field_name, options) => {
    const self = this
    let buttons = options.map(opt => ({
      text: opt[0],
      action: function () {
        Popup.close()
        data[field_name] = opt[1]
        self.handleFinishOrder(data)
      }
    }))
    buttons.push({
      text: intl.get('Anuluj'),
      className: 'cancel-button',
      action: function () {
        Popup.close()
      }
    })
    Popup.create({
      content: intl.get('Wybierz gabaryt paczki'),
      buttons: {
        right: buttons
      }
    })
  }

  handleFinishOrder(data){
    const { finishOrderAction, inventory_manage_materials, material_products } = this.props
    if (inventory_manage_materials && material_products.length){
      Popup.plugins().materials(data, this)
      return
    }
    finishOrderAction(data)
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const { order, inpost_dostawy, orlen_dostawy, pp_dostawy, wza_dostawy } = this.props
    const { orderSubstitutes } = this.state
    let data = {}

    document.querySelectorAll('input').forEach(input => {
      if(input.type === 'radio' && !input.checked){ return }
      let val = input.value
      if ((val !== '') && !isNaN(val)){ val = parseInt(val) }
      if(input.type === 'checkbox'){
    	   val = input.checked
      }
      data[input.name] = val
    })

    if(orderSubstitutes){
      data['substitutes'] = Object.keys(orderSubstitutes).map(line_id => ({
        'line_id': parseInt(line_id),
        'original_qty': order.products.find(product => ( product.line_id === parseInt(line_id) )).product_uom_qty,
        'substitute_choosen': orderSubstitutes[line_id]
        }))
    }

    const serialValues = []
    document.querySelectorAll('.serial_value').forEach(val => {
      serialValues.push(JSON.parse(val.innerHTML))
    })
    if(serialValues.length > 0){
      data.lots = serialValues
    }

    if((data['carrier_id'] || order.carrier_id) &&
       inpost_dostawy.includes(data['carrier_id'] || order.carrier_id) &&
       order.shipping_order_ids.some((el) => {
         return (typeof el.date === 'undefined' || !el.date) &&
                !el.shipping_parcels_ids.some((parcel) => {
                  return parcel.size
                })
       })
     ){
       this.sizePopup(data, 'inpost_size', inpost_size_options)
    } else if((data['carrier_id'] || order.carrier_id) &&
       orlen_dostawy.includes(data['carrier_id'] || order.carrier_id) &&
       order.shipping_order_ids.some((el) => {
         return (typeof el.date === 'undefined' || !el.date) &&
                !el.shipping_parcels_ids.some((parcel) => {
                  return parcel.size
                })
       })
     ){
       this.sizePopup(data, 'orlen_size', orlen_size_options)
    } else if((data['carrier_id'] || order.carrier_id) &&
       pp_dostawy.includes(data['carrier_id'] || order.carrier_id) &&
       order.shipping_order_ids.some((el) => {
         return (typeof el.date === 'undefined' || !el.date) &&
                !el.shipping_parcels_ids.some((parcel) => {
                  return parcel.size
                })
       })
     ){
       this.sizePopup(data, 'pp_size', pp_size_options)
    } else if((data['carrier_id'] || order.carrier_id) &&
       wza_dostawy.includes(data['carrier_id'] || order.carrier_id) &&
       order.shipping_order_ids.some((el) => {
         return (typeof el.date === 'undefined' || !el.date) &&
                !el.shipping_parcels_ids.some((parcel) => {
                  return parcel.size
                })
       })
     ){
       this.sizePopup(data, 'wza_size', wza_size_options)
    } else {
      this.handleFinishOrder(data)
    }
  }

  toggleConfirmButton = () => {
    let state = {}
    const {progress, valid} = this.state
    if(!document.querySelector('.produkt .mainCheckbox:not(.checked), .mainCheckbox.uwagiCheckbox:not(.checked)')
       && !document.querySelector('.produkt .productVariantValue.proper:not(.selected)')){
      state.valid = true
    } else {
      state.valid = false
    }
    //Progress
    let all = document.querySelectorAll('.produkt .mainCheckbox, .mainCheckbox.uwagiCheckbox').length
    let checked = 0
    if(document.querySelector('.mainCheckbox.uwagiCheckbox.checked')){
      checked += 1
    }
    document.querySelectorAll('.produkt .mainCheckbox.checked').forEach((item) => {
      if (!item.querySelector('.productVariantValue.proper:not(.selected)')){
        checked += 1
      }
    })
    state.progress = checked / all * 100
    if(all === 0){
      state.progress = 100
      state.valid = true
    }
    if(state.progress !== progress || state.valid !== valid){
      this.setState(state)
    }
  }

  showShipping = (id) => {
    const { selectShippingAction } = this.props
    selectShippingAction(id)
  }

  newShipping = () => {
    const { selectShippingAction } = this.props
    selectShippingAction(0)
    return
  }

  deleteShipping = (shippingId) => {
    const { deleteShippingAction } = this.props
    Popup.create({
      content: intl.get('Czy na pewno chcesz usunąć przesyłkę?'),
      buttons: {
        right: [
          {
            text: intl.get('Tak'),
            className: 'ok',
            key: 'enter',
            action: function () {
              deleteShippingAction(shippingId)
            }
          },
          {
            text: intl.get('Anuluj'),
            className: 'cancel-button',
            action: function () {
              Popup.close()
            }
          }
        ]
      }
    }, true)
  }

  shippingList = () => {
    Popup.plugins().shipping(this.newShipping, this.deleteShipping, this)
  }

  noteCheckboxClick = (event) => {
    let $checkbox = event.currentTarget
    if ($checkbox.tagName === 'DIV'){
      $checkbox = $checkbox.parentElement.querySelector('.uwagiCheckbox')
    }
    $checkbox.classList.toggle('checked')
    this.toggleConfirmButton()
  }

  setOrderSubstitutes = (line_id, substitutes) => {
    const { orderSubstitutes } = this.state
    this.setState({
      orderSubstitutes: {
        ...orderSubstitutes,
        [line_id]: substitutes
      }
    })
  }

  getOrderSubstitutes = (line_id) => {
    const { orderSubstitutes } = this.state
    return orderSubstitutes[line_id]
  }

  render() {
    const { authenticated } = this.props
    if(!authenticated) return (<Redirect to='/login' />)
    const odoo = window.ODOO.url + (window.ODOO.port?':'+window.ODOO.port:'')
    const {
      order,
      pack,
      product_confirmation_method,
      show_company,
      use_packing_zone_error_label,
      show_shop,
      confirm_note,
      carrier_selection,
      group_treshold,
      add_delivery,
      add_package,
      processing
    } = this.props
    const { progress, valid } = this.state
    return (
      <>
      <ProgressBar percent={progress}/>
      <div className="pakowanie">
        <style dangerouslySetInnerHTML={{__html: `
          #app header, #app footer{ display: none }
          #app{ display: block }
        `}} />
        <div>
          <div className="pakowanie_header">
            <button className="pull-left secondary" onClick={this.deselectOrder}>{intl.get('Wstecz')}</button>
            { ['scan', 'scan_click'].includes(product_confirmation_method) &&
              <div className="scanner_wrapper">
                <input id="scanner"
                       type="text"
                       name="scanner"
                       placeholder={intl.get('Wprowadź kod produktu')}
                       onKeyPress={this.handleScanner}
                       autoComplete="off"/>
              </div>
            }
            <button onClick={this.handleSubmit} className="pull-right" id="zatwierdz" disabled={!valid || processing} >{intl.get('Dalej')}</button>
          </div>
          {order &&
            <div className="pakowanie_content row">
              <input type="hidden" name="order_id" value={order.id}/>
              <input type="hidden" name="paczka" value={pack}/>
              <div className="col-lg-5">
                <div className="pakowanie_order ramka">
                  <h4>
                    {intl.get('Zamówienie')} {order.name}
                  </h4>
                  <div className="order_data">
                    <div className="notes f_group">
                      <label>{intl.get('Uwagi klienta')}:</label>
                      <div>
                        {confirm_note && order.note &&
                            <div className="checkbox mainCheckbox uwagiCheckbox" onClick={this.noteCheckboxClick}>
                              <Check className="check"/>
                            </div>
                        }
                        <div className="note_text" onClick={this.noteCheckboxClick}>{order.note}</div>
                      </div>
                    </div>
                    <div className="carrier f_group">
                      <label>{intl.get('Metoda dostawy')}:</label>
                      {carrier_selection && order.carriers &&
                        <div className="carriers_radios">
                          {order.carriers.map(carrier => {
                            return(<div className="carrier_radio" key={carrier.id}>
                              <div className="carrier_thumbnail" style={{color: '#ccc', backgroundImage: `url("${odoo}/ideaerp_shipment_${order.carrier_comp}/static/img/logo.png")`}}/>
                              <input type="radio"
                                     name="carrier_id"
                                     id={'carrier_'+carrier.id}
                                     value={carrier.id}
                                     defaultChecked={order.carrier_id === carrier.id ? 'checked':''}/>
                              <label className={order.carrier_id === carrier.id ? 'initial':''}
                                     htmlFor={'carrier_'+carrier.id}
                                     onClick={this.confirmCarrierChange} data-value={carrier.id}>{carrier.name}</label>
                            </div>)
                          })}
                        </div>
                      }
                      <div>
                        {order.carrier_name}
                        <div>
                          <input type="hidden" name="carrier_id" value={order.carrier_id || ''}/>
                          {order.carrier_comp &&
                            <span className="shippinglogo">
                                <img src={`${odoo}/ideaerp_shipment_${order.carrier_comp}/static/img/logo.png`} alt="carrier"/><br/>
                            </span>
                          }
                        </div>
                      </div>
                      {(add_package || add_delivery) &&
                        <div className="add_package_wrapper">
                          {add_delivery &&
                            <button id="add_delivery" onClick={this.addDelivery}>{intl.get('Dodaj przesyłkę')}</button>
                          }
                          {add_package &&
                            <button id="add_package" onClick={this.addPackage}>{intl.get('Dodaj paczkę')}</button>
                          }
                        </div>
                      }
                    </div>
                      {order.shipping_parcel_count &&
                        <div className="carrier f_group">
                          <label>{intl.get('Ilość paczek')}:</label>
                          <div>{order.shipping_parcel_count}</div>
                        </div>
                      }
                    {show_company &&
                      <div className="date_order f_group">
                        <label>{intl.get('Firma')}:</label>
                        <div>{order.company}</div>
                      </div>
                    }
                    {show_shop &&
                      <div className="date_order f_group">
                        <label>{intl.get('Sklep')}:</label>
                        <div>{order.shop_name}</div>
                      </div>
                    }
                    {order.packing_zone_cart_names.length > 0 &&
                      <div className="date_order f_group">
                        <label>{intl.get('Wózki')}:</label>
                        <div>{order.packing_zone_cart_names}</div>
                      </div>
                    }
                    <div className="date_order f_group">
                      <label>{intl.get('Data zamówienia')}:</label>
                      <div>{order.date_order}</div>
                    </div>
                    <div className="amount_total f_group">
                      <label>{intl.get('Kwota zamówienia')}:</label>
                      <div>{order.amount_total}</div>
                    </div>
                    {order.integration_login &&
                    <div className="integration_login f_group">
                      <label>{intl.get('Login klienta')}:</label>
                      <div>{order.integration_login}</div>
                    </div>
                    }
                    {order.allegro_account &&
                      <div className="allegro_account f_group">
                        <label>{intl.get('Login Allegro sprzedawcy')}:</label>
                        <div>{order.allegro_account}</div>
                      </div>
                    }
                    <div className="delivery f_group">
                      <label>{intl.get('Adres dostawy')}:</label>
                      <div>
                        <div>{order.delivery_name}</div>
                        <div>{order.delivery_street}</div>
                        <div>{order.delivery_zip} {order.delivery_city}</div>
                        {order.delivery_phone &&
                        <div>{order.delivery_phone}</div>
                        }
                        {order.delivery_email &&
                        <div>{order.delivery_email}</div>
                        }
                      </div>
                    </div>
                    <div className="invoice f_group">
                      <label>{intl.get('Potwierdzenie')}:</label>
                      <div>{order.invoice_option ? intl.get('faktura') : intl.get('paragon')}</div>
                    </div>
                    {order.warehouseman &&
                    <div className="warehouseman f_group">
                      <label>{intl.get('Osoba zbierająca')}:</label>
                      <div>{order.warehouseman}</div>
                    </div>
                    }
                  </div>
                  <div className="text-center buttons_wrap">
                   <div>
                    <button onClick={this.shippingList}>{intl.get('Przesyłki')}</button>
                    {use_packing_zone_error_label &&
                      <button className="secondary" onClick={this.printErrorLabel}>{intl.get('Drukuj etykietę zastępczą')}</button>
                    }
                   </div>
                  </div>
                </div>
              </div>
              <div className={'pakowanie_products col-lg-7'+(order.products.length>3?' many_products':'')}>
                {order.products.map(product => getProductListItem(product, this, (group_treshold || 10)))}
              </div>
            </div>
          }
        </div>
      </div>
      </>
    )
  }
}
const Order = connect(mapStateToProps, mapDispatchToProps)(OrderComponent)
export default withRouter(Order)
