import React from 'react'
import Popup from 'react-popup'
import intl from 'react-intl-universal'
import Lightbox from 'react-simple-lightbox'
import Autosuggest from 'react-autosuggest'
import { connect } from 'react-redux'
import { b64EncodeUnicode } from '../utils/Tools'
import { ReactComponent as Check } from '../assets/check.svg'

function printBundle(product, parent, group_treshold){
  const { product_confirmation_method } = parent.props
  let product_uom_qty = product.product_uom_qty
  if(product.product_uom_qty < group_treshold){
    product_uom_qty = 1
  }
  return (
    <div className="ramka bundleItemList">
      <table>
        <tbody>
          {product.items.map((item, index) => {
            const barcodeString = '#' + (item.barcode || '') + '#' + item.additional_barcodes_ids.join('#') + '#'
            return (<tr data-barcode={barcodeString} data-name={b64EncodeUnicode(item.product_name || '')} data-code={item.default_code || ''} data-qty={item.qty_uom} key={`${product.line_id}_${index}`}>
              <td>
                [{item.default_code || ''}] {item.name}
              </td>
              <td className="width1">
                <span className="smaller">{product_uom_qty} x {item.qty_uom} = </span>
                {product_uom_qty * item.qty_uom} szt.
              </td>
              <td className="width1">
                <div className={`checkbox multiproduct${['click', 'scan_click'].includes(product_confirmation_method)?' clickable':''}`} data-bundle-item-qty={item.qty_uom} onClick={parent.checkboxClick}>
                  <Check className="check"/>
                </div>
              </td>
            </tr>)
        })}
        </tbody>
      </table>
    </div>
  )
}

const mapStateToProps = state => ({
  product_confirmation_method: state.config.product_confirmation_method,
  verify_qty: state.config.verify_qty,
  stock_location: state.config.stock_location,
  group_treshold: state.config.group_treshold ? parseInt(state.config.group_treshold) : false,
  bundle_select: state.config.bundle_select || false,
  order: state.pack.order
})

class ProductListItemComponent extends React.Component {
  constructor(props) {
    super(props)
    const { parent, product } = this.props
    this.state = {
      selectedLot: [false, ''],
      lots: props.product.lots || [],
      substitutes: parent.getOrderSubstitutes(product.line_id)
    }
  }

  getLots = (value) => {
    const inputValue = value.trim().toLowerCase()
    const {lots} = this.props.product
    return inputValue.length === 0 ? lots : lots.filter(lot =>
      lot[1].toLowerCase().includes(inputValue)
    )
  }

  onLotsFetchRequested = ({ value }) => {
    this.setState({
      lots: this.getLots(value)
    })
  }

  handleLotChange = (event, { newValue }) => {    
    this.setState({
      selectedLot: this.props.product.lots.find(lot => lot[0] === newValue) || [false, newValue]
    })
  }

  setSubstitutes = (id, val) => {
    const { substitutes } = this.state
    this.setState({
      substitutes: {
        ...substitutes,
        [id]: val
      }
    })
  }

  confirmSubstitutes = () => {
    const { substitutes } = this.state
    const { parent, product, qty } = this.props
    const wantedQty = typeof product.line_qty !== 'undefined' ? product.line_qty : qty
    const sumVals = Object.values(substitutes || {}).reduce((a, b) => parseInt(a) + parseInt(b), 0)
    if(sumVals !== wantedQty){
      document.querySelector('.mismatch').classList.add('shake')
      setTimeout(() => document.querySelector('.mismatch').classList.remove('shake'), 500)
      return false
    }    
    parent.setOrderSubstitutes(product.line_id, substitutes)
    return true
  }

  resetSubstitutes = () => {
    const { substitutes } = this.state
    const { parent, product } = this.props
    this.setState({
      substitutes: parent.getOrderSubstitutes(product.line_id)
    })
  }


  checkboxClick = (event) => {
    const { product_confirmation_method, verify_qty, group_treshold, product, parent, bundle_select, productTracking } = this.props
    const { selectedLot } = this.state
    if(productTracking && selectedLot[1] === ''){
      return
    }
    const bundle_qty = event.currentTarget.dataset['bundleItemQty']
    if(['click', 'scan_click'].includes(product_confirmation_method)){
      if(product.is_bundle &&
         event.currentTarget.classList.contains('mainCheckbox') &&
         !bundle_select){
        return
      }
      let $checkbox = event.currentTarget
      let $produkt = event.currentTarget.closest('.produkt.ramka')
      let cnt = parseInt($produkt.getAttribute('data-prods'))
      if(typeof bundle_qty != 'undefined'){
        cnt = cnt * parseInt(bundle_qty)
      }
      const self = this
      if(verify_qty && cnt >= (group_treshold || 10) && !$checkbox.classList.contains('checked')){
        Popup.create({
          content: intl.get('Czy na pewno spakowałeś X pozycji tego SKU?', {cnt: cnt}),
          buttons: {
            right: [{
              text: intl.get('Tak'),
              key: 'enter',
              action: function () {
                Popup.close()
                $checkbox.classList.toggle('checked')
                if(!$checkbox.classList.contains('mainCheckbox')){
                  self.bundleItemsCollected($produkt, parent)
                }
                parent.toggleConfirmButton()
              }
            },
            {
              text: intl.get('Nie'),
              className: 'cancel-button',
              action: function () {
                Popup.close()
              }
            }]
          }
        })
        return
      } else {
        $checkbox.classList.toggle('checked')
        if(!event.currentTarget.classList.contains('mainCheckbox')){
          self.bundleItemsCollected($produkt, parent)
        }
      }
      if($produkt.querySelector('.bundleItemList')){
        if(event.currentTarget.classList.contains('mainCheckbox') && bundle_select){
          let elems = $produkt.querySelectorAll('.bundleItemList .checkbox')
          for (let i = 0; i < elems.length; ++i) {
            elems[i].classList.toggle('checked', $checkbox.classList.contains('checked'))
          }
        }
      }
      parent.toggleConfirmButton()
    }
  }

  bundleItemsCollected = ($produkt, parent) => {
    const alldone = !$produkt.querySelector('.bundleItemList .checkbox:not(.checked)')
    $produkt.querySelector('.product_checkbox .checkbox').classList.toggle('checked', alldone)
  }

  render() {
    const { product, qty, supercount, stock_location, group_treshold, product_confirmation_method, productTracking, order } = this.props
    const { lots, selectedLot } = this.state
    const odoo = window.ODOO.url + (window.ODOO.port?':'+window.ODOO.port:'')+'/product/image?default_code='
    let className = 'ramka produkt'+
                    (supercount?' supercount':'')+
                    (product.red?' red':'')+
                    (product.is_bundle?' has_bundle':'')+
                    (product.show_as_dropshipping_product?' dropshipping_product':'')+
                    (productTracking && selectedLot[1] === '' ? ' blocked_by_serial' : '')
    let service = product.product_type === 'service'    
    const barcodeString = '#' + (product.barcode || '') + '#' + product.additional_barcodes_ids.join('#') + '#'
    return (
      <div className={className} data-prods={qty} data-barcode={barcodeString} data-name={b64EncodeUnicode(product.product_name || '')} data-code={product.default_code || ''}>
        <div>
          <div className="dropshipping_label">Dropshipping</div>
          <div className="scanned_icon_wrapper"><div/></div>
          { product.substitute_products && product.substitute_products.length > 0 && 
            <div className="substitutes">
              <div className={`substitutes__toggle ${product.line_qty ? 'active' : ''}`} onClick={() => { 
                Popup.plugins().substitutes(
                  product.substitute_products, 
                  this.setSubstitutes, 
                  this.confirmSubstitutes, 
                  this.resetSubstitutes, 
                  typeof product.line_qty !== 'undefined' ? product.line_qty : qty, 
                  order.note) 
              }}/>
            </div>
          }
          <Lightbox key={product.line_id}>
            <img src={odoo+product.quote_default_code} alt="product"/>
          </Lightbox>
          <div className="product_details">
            <h4>
              {product.product_name}
              <span className='product_details__codes'>
                {product.default_code &&
                  <div className="default_code" data-code={product.default_code}><label>SKU:</label> [{product.default_code}]</div>
                }
                {product.barcode &&
                  <div className="product_details__barcode"><label>EAN:</label> [{product.barcode}]</div>
                }
              </span>
            </h4>
            { productTracking && 
              <>
                <div className="serial f_group">
                  <label>{intl.get('Partia / Numer seryjny')}:</label>
                  <div>
                    <Autosuggest
                      suggestions={lots || []}
                      onSuggestionsFetchRequested={this.onLotsFetchRequested}
                      getSuggestionValue={(lot) => lot[0]}
                      renderSuggestion={(lot) => lot[1]}
                      shouldRenderSuggestions={() => true}
                      inputProps={{
                        value: selectedLot[1],
                        onChange: this.handleLotChange,
                        name: `lot${product.default_code}`
                      }} 
                    />
                  </div>
                </div>
                <div className="serial_value">{JSON.stringify(selectedLot)}</div>
              </>
            }
            <div className="qty f_group">
              <label>{intl.get('Ilość')}:</label>
              <div>{qty} {product.uom_name}</div>
            </div>
            <div className="price f_group">
              <label>{intl.get('Cena jednostkowa')}:</label>
              <div>{product.price_unit_gross}</div>
            </div>
            {stock_location &&
              <div className="stock_location f_group">
                <label>{intl.get('Strefa składowania')}:</label>
                <div>{product.location}</div>
              </div>
            }
            {product.packing_zone_notes &&
              <div className="notes f_group">
                <label>{intl.get('Uwagi do pakowania')}:</label>
                <div>{product.packing_zone_notes}</div>
              </div>
            }
          </div>
          {!service && !product.show_as_dropshipping_product &&
            <div className={`product_checkbox${['click', 'scan_click'].includes(product_confirmation_method)?' clickable':''}`} data-line={product.line_id}>
              <div className="checkbox mainCheckbox" onClick={this.checkboxClick}>
                <Check className="check"/>
              </div>
            </div>
          }
        </div>
        { product.is_bundle &&
          <div className="bundleWrapper">
            {printBundle(product, this, (group_treshold || 10))}
          </div>
        }
      </div>
    )
  }
}

const ProductListItem = connect(mapStateToProps)(ProductListItemComponent)
export default ProductListItem
