import Popup from 'react-popup'
import intl from 'react-intl-universal'
import { SELECT_SHIPPING, GET_SHIPPING, SAVE_SHIPPING } from '../constants/ActionTypes'

const initialState = {
  shipping: false,
  shipping_data: false
}

export default function pack(state = initialState, action) {
  switch (action.type) {
    case SELECT_SHIPPING:
      return { ...state,
        shipping: action.payload
      }
    case GET_SHIPPING:
      return { ...state,
        shipping_data: action.payload
      }
    case SAVE_SHIPPING:
      Popup.close()
      Popup.alert(intl.get('Zapisano'))
      return initialState
    default:
      return state
  }
}
