import React, { useState } from 'react'
import Popup from 'react-popup'
import { connect } from 'react-redux'
import intl from 'react-intl-universal'

const Counter = ({currentVal, setSubstitute, max}) => {
  const [val, setVal] = useState(currentVal)
  const onChange = (e) => {
    setVal(parseInt(e.currentTarget.value))
    setSubstitute(parseInt(e.currentTarget.value))
  }
  const increment = (by) => {
    const newVal = val + by
    if(newVal > max || newVal < 0){
      return
    }
    setVal(newVal)
    setSubstitute(newVal)
  }
  return (<div className='counter'>
    <button onClick={() => increment(-1)}>-</button>
    <input type="number" value={val} onChange={onChange} min="0" max={max}/>
    <button onClick={() => increment(1)}>+</button>
  </div>)
}

const mapStateToProps = state => ({
  order: state.pack.order
})

class SubstitutesComponent extends React.Component {

  constructor(props) {
    const { substitutes } = props
    super(props)
    this.state = {
      vals: substitutes.reduce((acc, cur) => ({
        ...acc,
        ...(cur.value ? { [cur.product_id]: cur.value } : {})
      }), {})
    }
  }

  getRow = (substitute) => {
    const { setSubstitutes } = this.props
    const { vals } = this.state
    return (
      <tr data-id={substitute.product_id}
          key={substitute.product_id}>
        <td>[{substitute.default_code}] {substitute.description}</td>
        <td>{substitute.qty_available}</td>
        <td>
          <Counter 
            currentVal={ substitute.value || 0 } 
            setSubstitute={ (val) => { 
              setSubstitutes(substitute.product_id, val) 
              this.setState({
                vals: {
                  ...vals,
                  [substitute.product_id]: val
                }
              })
            } } 
            max={substitute.qty_available}
          />
        </td>
      </tr>
    )
  }

  render() {
    const { substitutes, qty, note } = this.props
    const { vals } = this.state
    const sumVals = Object.values(vals).reduce((a, b) => parseInt(a) + parseInt(b), 0)
    return (
      <div className='substitute_modal'>
        { note &&
          <div className='substitute_modal__note'>{note}</div>
        }
        <div className='table-wrapper'>
          <table className='table substitutes_list'>
            <thead>
              <tr>
                <th>{ intl.get('Produkt') }</th>
                <th>{ intl.get('Dostępne') }</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {substitutes.map(substitute => this.getRow(substitute))}
            </tbody>
            <tfoot>
              <tr>
                <th></th>
                <th></th>
                <th className={sumVals === qty ? '' : 'mismatch'}>
                  <div>{ sumVals } / { qty }</div>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    )
  }
}

const Substitutes = connect(mapStateToProps)(SubstitutesComponent)

Popup.registerPlugin('substitutes', function (substitutes, setSubstitutes, confirmSubstitutes, resetSubstitutes, qty, note) {

    this.create({
        title: intl.get('Zamienniki'),
        content: <Substitutes substitutes={substitutes} setSubstitutes={setSubstitutes} qty={qty} note={note}/>,
        buttons: {
          right: [
            {
              text: intl.get('Zapisz'),
              action: function () {
                const allow = confirmSubstitutes()
                if(allow) {
                  Popup.close()
                }
              }
            },
            {
              text: intl.get('Anuluj'),
              className: 'cancel-button',
              action: function () {
                resetSubstitutes()
                Popup.close()
              }
            }
          ]
        }
    })
})
