import React from 'react'
import Popup from 'react-popup'
import intl from 'react-intl-universal'
import Autosuggest from 'react-autosuggest'
import { connect } from 'react-redux'
import { getOrder, selectPack, getSuggestions } from '../actions/PackActions'
import ProductSuggestions from './ProductSuggestions'

function getSuggestionValue(suggestion) {
  return suggestion
}
function renderSuggestion(suggestion) {
  return (
    <span>{suggestion}</span>
  )
}

const mapStateToProps = state => ({
  authenticated: state.auth.session.id !== false,
  show_suggestions: state.config.product_suggestions,
  no_packages: state.config.no_packages,
  wozek: state.config.wozek,
  suggestions: state.pack.suggestions,
  searching: state.pack.searching,
  pack: state.router.location.query.pack ? parseInt(state.router.location.query.pack) : false,
})

const mapDispatchToProps = dispatch => ({
  getSuggestionsAction: (pack, key) => dispatch(getSuggestions(pack, key)),
  getOrderAction: (kod, pack, key) => dispatch(getOrder(kod, false, pack, false, key)),
  selectPackAction: () => dispatch(selectPack()),
})

class ScanProductComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      kod: '',
      error: false,
      tips: []
    }
  }

  componentDidMount() {
    const { getSuggestionsAction, authenticated, pack, show_suggestions } = this.props
    if(authenticated) getSuggestionsAction(pack, show_suggestions)
    document.getElementById('kod').focus()
  }

  deselectPack = () => {
    const { selectPackAction } = this.props
    selectPackAction()
  }

  handleChange = (event, { newValue }) => {
    this.setState({
      kod: newValue
    })
  }

  handleKey = (event) => {
    if (event.which === 13) {
      this.handleSubmit(event)
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { pack, getOrderAction, show_suggestions } = this.props
    const kod = document.getElementById('kod').value
    if (kod){
      getOrderAction(kod, pack, show_suggestions)
    } else {
      Popup.alert(intl.get('Wprowadź kod produktu'))
    }
  }

  getTips = (value) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    const {suggestions} = this.props
    const allCodes = [
      ...suggestions.bundles.map(o => o.default_code),
      ...suggestions.products.map(o => o.default_code),
      ...suggestions.products.map(o => o.name)
    ].filter((v, i, s) => (v && s.indexOf(v) === i))
    return inputLength === 0 ? [] : allCodes.filter(code =>
      code.toLowerCase().slice(0, inputLength) === inputValue
    )
  }

  onTipsFetchRequested = ({ value }) => {
    this.setState({
      tips: this.getTips(value)
    })
  }

  onTipsClearRequested = () => {
    this.setState({
      tips: []
    })
  }

  render() {
    const { suggestions, show_suggestions, no_packages, wozek, searching } = this.props
    const { kod, error, tips } = this.state
    const inputProps = {
      value: kod,
      placeholder: intl.get('Kod produktu'),
      onChange: this.handleChange,
      onKeyPress: this.handleKey,
      className: 'searchBox',
      id: 'kod',
      name: 'kod'
    }
    return (
      <div className="container">
        <form onSubmit={this.handleSubmit}>
          <div className="ramka center">
            <h4>{intl.get('Skanowanie kodu')}</h4>
            <Autosuggest
              suggestions={tips}
              onSuggestionsFetchRequested={this.onTipsFetchRequested}
              onSuggestionsClearRequested={this.onTipsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps} />
            { error &&
              <div className="failed">
                {intl.get('Nie znaleziono zamówienia dla produktu!')}'
              </div>
            }
            <div className="buttons_wrap">
              { (wozek || !no_packages) &&
                <button className="pull-left secondary" onClick={this.deselectPack}>{intl.get('Anuluj')}</button>
              }
              <input type="submit" className="pull-right" value={intl.get('Dalej')} disabled={searching} />
            </div>
          </div>
          <ProductSuggestions codes={suggestions} visible={show_suggestions} parent={this}/>
        </form>
      </div>
    )
  }
}

const ScanProduct = connect(mapStateToProps, mapDispatchToProps)(ScanProductComponent)
export default ScanProduct
